.made-by-div{
    max-width: 700px;
    background-color: #F6F6F6;
    height: 220px;
    border-radius: 30px;
    margin: 50px auto;
}
.creator-de{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: inherit;
}
.creator-img{
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-color: #D9D9D9;
    position: absolute;
    margin-bottom: 215px;
}
.creator h3{
    font-weight: bold;
}
.creator h6{
    color: #A2D83E;
}
.ctp-sd{
    background-color: #F6F6F6;
    border-radius: 30px;
    padding: 20px 50px;
    width: 85%;
    margin-top: 100px;
}
.ctp-form{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.ctp-fd1{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
}
.ctp-sddd2{
    margin-top: 20px;
}
.ifctp{
    width: 100%;
    border-radius: 0px;
    border: none;
    padding: 20px;
    margin-right: 5px;
}
.tfctp{
    width: 99.5%;
    border: none;   
    padding: 10px 15px;
}
.bn{
    border: none;
}
.ch1{
    margin-top: 40px;
    margin-bottom: 35px;
    width: 100%;
}
@media screen and (max-width: 1300px) {
    .ctp-sd{
        width: 100%;
    }
}
@media screen and (max-width: 400px) {
    .ctp-sd{
        padding: 20px 20px;
        margin-top: 50px;
    }
    .ch1{
        margin-top: 10px;
        margin-bottom: 20px;
    }
}