.a1sd{
    display: flex;
}
.a1sdd1{
    width: 60%;
}
.a1sdd2{
    width: 40%;
    display: flex;
    align-items: center;
}
.a1sdd1d{
    background-color: #EFFFCE;
    margin: 0;
    margin-left: -100px;
    width: 100px;
    z-index: 1;
    height: 85%;
}
.a1s{
    margin-top: 40px;
    margin-bottom: 80px;
}
.overline{
    height: 3px;
    background-color: #EFFFCE;
    position: absolute;
    width: 253px;
    margin-top: -258px;
}
.a1sdd2dbtn{
    background-color: #A2D83E;
    border: none;
    width: 150px;
    height: 50px;
}
.agdstepperbox{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
.gd{
    width: 600px;
    height: 300px;
    margin-top: 40px;
    align-items: center;
    display: flex;
}
.gdd{
    background-color: #F3F3F3;
    width: 100%;
    height: 200px;
    border-radius: 10px;
}
.refimgcont{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.referenceimg{
    filter: grayscale(100%);
}
.elementdet{
    display: flex;
    padding: 50px 30px 10px 30px;
}
.elementdet2{
    display: flex;
    padding: 0px 30px;
    text-align: start;
}
.elementdet3{
    display: flex;
    position: absolute;
    margin-left: 46vh;
    margin-top: 2%;
}
.elementimg{
    width: 80px;
    border-radius: 50px;
    position: absolute;
    margin-left: -25vh;
    margin-top: -5vh;
}
.elementname{
    font-weight: bold;
    font-size: 20px;
}
.elementjob{
    font-size: 20px;
    color: gray;
    margin-left: 5px;
}
.elementquote{
    color: black;
    font-size: 16px;
}
.gfd{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.shake{
    animation: popup 1s infinite;
}
@keyframes popup {
  0%  { opacity: 1 }
  50% { opacity: 0   }
  100%{ opacity: 1   }
}
.agdstepperbox h1{
    width: fit-content;
    font-weight: bold;
}
.ags1{
    margin: 100px 300px;
}
.a1sdd2d{
    width: 90%;
    padding: 20px 10px;
    box-shadow: rgb(17 12 46 / 15%) 0px 48px 100px 0px;
    height: 85%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: white;
}
.a1sdd2d p{
    color: #5D5D5D;
}
.a1sdd2d h1 {
    width: 100%;
}
.a1sdd1i{
    width: 100%;
    height: 60vh;
}
.quote-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: fit-content;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 40px;
    margin-bottom: 40px;
}
.quote-div h1{
    -webkit-text-stroke: 1px #A2D83E;
    color: transparent;
    width: auto;
    letter-spacing: 10%;
    text-transform: uppercase;
    font-size: 40px;
}
.quote-div p{
    color: black;
}
.a2sd{
    display: flex;
    flex-direction: column;
    justify-content: center;   
    margin-left: 5%;
    margin-right: 5%; 
    margin-bottom: 120px;
    padding-top: 120px;
}
.reveal{    
    transform: translateY(100px);
    opacity: 0;
    transition: 1s all ease;
  }
  .reveal-right{    
    transform: translateY(100px);
    opacity: 0;
    transition: 1s all ease;
  }
  
  .reveal.active3{
    transform: translateY(0);
    opacity: 1;
  }
  .revealRight.active4{
    transform: translateX(0);
    opacity: 1;
  }
.a2sd h1{
    width: 60%;
    font-weight: bold;
    margin-bottom: 60px;
}
.a2sdd{
    display: flex;
    justify-content: space-around;
    width: 100%;
}
.a2sddd{
    background-color: #EFFFCE;
    display: flex;
    border-radius: 20px;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}
.svg{
    position: absolute;
    margin-top: -335px;
    margin-left: -23%;
    width: 80px;
}
.a2sdddd{
    display: flex;
    width: 350px;
    height: 350px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.a2sdddd p{
    color: #5D5D5D;
    font-size: 20px;
    margin-left: 7px;
    margin-right: 7px;
}
.a2sdddd h3{
    font-weight: bold;
    margin-top: 15px;
}
.a3sd{
    display: flex;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
}
.a3sdd1{
    width: 40%;
    margin-right: 75px;
    margin-left: 5%;
}
.a3sdd1 h1{
    width: 100%;
    font-weight: bold;
}
.a3sdd1 p{
    width: 100%;
    color: #5D5D5D;
    font-size: 14px;
}
.a3sdd1 button{
    background-color: #A2D83E;
    color: white;
    border-radius: 10px;
    border: none;
    padding: 10px 15px;
    font-size: 13px;
    transition: 0.5s ease-in-out;
    border: 1px solid #A2D83E;
}
.a3sdd1 button:hover{
    color: black;
    background-color: #EFFFCE;
    
}
.a3sdd2{
    display: flex;
}
.a3sdd2d{
    height: 400px;
    margin-left: 20px;
    border-radius: 20px;
    width: 300px;
    display: flex;
    justify-content: end;
    align-items: flex-end;
    padding: 10px;
    color: white;
    flex-direction: column;
    transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.glow-wrap{
    overflow: hidden;
    position: absolute;
    width: inherit;
    height: 100%;
    top: 0;
    margin-top: -10px;
    z-index: 5;
  }
  
.glow{
    display: block;
    position:absolute;
    width: 30%;
    height: 82%;
    background: rgba(255,255,255,.2);
    top: 0;
    filter: blur(5px);
    transform: rotate(45deg) translate(-450%, 0);
    transition: all 1.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    left: 80px;
  }
  
.a3sdd2d:hover .glow{
    transform: rotate(45deg) translate(450%, 0);
    transition: all 1.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  
  .a3sdd2d:hover .glow-wrap{
    margin-top: 0;
  }
  
.a3sdd1d2{
    display: flex;
    margin-top: 20px;
}
.dot{
    height: 15px;
    width: 15px;
    border: 1px solid black;
    margin: 5px;
    border-radius: 50%;
    cursor: pointer;
}
.active2{
    background-color: #EFFFCE;
}
.step-right{
    position: absolute;
    border: 1px solid black;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    padding: 12px;
    margin-left: 64%;
    margin-top: 180px;
    cursor: pointer;
    transition: 0.5s ease-in-out;
}
.step-right:hover{
    fill: white;
    background-color: black;
}
.product-img{
    height: 200px;
    width: fit-content;
}
.a3sdd2d p{
    z-index: 4;
    color: black;
    width: 100%;
    font-size: 14px;
}
.prodimgwrapdiv{
    width: inherit;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}
.a3sdd2d h2{
    z-index: 4;
    color: black;
    font-weight: bold;
    font-size: 20px;
    width: 100%;
}
.a3sdd2{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
    overflow-y: scroll;
    column-gap: 20px;
}
.a3sdd2::-webkit-scrollbar {
    display: none; 
}
.a3sdd2{
    -ms-overflow-style: none; 
    scrollbar-width: none; 
    scroll-behavior: smooth;
}
.vec-ab3s{
    position: absolute;
    z-index: -1;
    height: auto;
}
.a4sd{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
}
.a4sd h1{
    color: black;
    font-weight: bold;
    font-size: 36px;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
}
.a4sdd{
    display: flex;
    flex-wrap: wrap;
    width: 100vh;
    justify-content: center;
    margin: 0;
    padding: 0;
}
.card-container {
    display: inline-block;
    margin: 0 auto;
    padding: 0 12px;
    perspective: 900px;
    text-align: center;
    
}
.card {
  position: relative;
  width: 250px;
  height: 300px;
  transition: all 1s ease;
  transform-style: preserve-3d;
  margin: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border: none;
  border-radius: 10px;
}
.profile-pic{
    z-index: 1;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    position: absolute;
}
.front, .back {
    position: absolute;
    background: #F3F3F3;
    box-shadow: none;
    width: 250px;
    height: 300px;
    border-radius: 10px;
    color: white;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
   
  .card-container:hover .card {
    transform: rotateY(180deg);
  }
  .back {
    transform: rotateY(180deg);
    align-items: center;
    padding: 10px;
  }
  .fa h6{
    color: #EFFFCE;
    font-size: 16px;
  }
  .About-intro{
    background-image: url(https://images.unsplash.com/photo-1623186786193-1294c5348b12?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80);
    height: 50vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex-direction: column;
}
.abvec1{
    position: absolute;
    z-index: -1;
    right: 1px;
}
.covernav2 {
    display: flex;
    justify-content: space-between;
    width: 180px;
    margin-top: 10px;
}
  .aisd h1{
    margin: 0;
    color: transparent;
    font-size: 70px;
    width: 100%;
    -webkit-text-stroke: 1px white;
  }
  .aisd{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 100%;
  }
  .fa-user{
    width: 100%;
    z-index: 2;
    box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
    border-radius: inherit;
  }
  .back p {
    color: #A2D83E;
    font-size: 20px;
    }
    .fa h3{
        font-size: 20px;
    }
    @media screen and (max-width: 1810px) {
        .ags1 {
            margin: 100px 200px;
        }
    }
    @media screen and (max-width: 1620px) {
        .gd {
            width: 550px;
        }
    }
    @media screen and (max-width: 1420px) {
        .ags1 {
            margin: 100px 100px;
        }
        .elementimg{
            margin-left: -19%;
        }
        .elementdet3{
            margin-left: 36%;
        }
        .gd {
            max-width: 600px;
        }
    }
    @media screen and (max-width: 1320px) {
        .gd {
            max-width: 500px;
        }
        .elementimg{
            margin-left: -17%;
        }
        .elementdet3{
            margin-left: 33%;
        }
    }
    @media screen and (max-width: 1220px) {
        .agdstepperbox{
            margin-bottom: 20px;
        }
        .gfd{
            flex-direction: column;
            align-items: center;
        }
        .gd {
            max-width: 600px;
            margin-top: 0px;
        }
        .elementimg{
            margin-left: -20.5%;
        }
        .elementdet3{
            margin-left: 38%;
        }
    }
    @media screen and (max-width: 1060px) {
        .elementimg{
            margin-left: -23%;
            margin-top: -4%;
        }
        .elementdet3{
            margin-left: 43%;
        }
        .abvec1{
            display: none;
        }
    }
    @media screen and (max-width: 916px) {
        .front, .back, .card{
            width: 200px;
            height: 250px;
        }
        .ags1 {
            margin: 100px 30px;
        }
        .a2sddd{
            flex-direction: column;
        }
        .svg{
            margin-left: -55%;
        }
        .a2sd h1{
            width: 100%;
        }
        .a1sdd1i {
            height: 55vh;
        }
        .a1sdd1 {
            width: 55%;
        }
        .a1sdd2 {
            width: 45%;
        }
        .elementimg{
            margin-left: -26vh;
        }
        .elementdet3{
            margin-left: 45vh;
            margin-top: 4%;
        }
    }
    @media screen and (max-width: 740px) {
        .a1sd {
            flex-direction: column;
            justify-content: center;
        }
        .front, .back, .card{
            width: 150px;
            height: 200px;
        }
        .a1sdd1 {
            width: 100%;
            position: absolute;
            z-index: -3;
        }
        .a1sdd2d {
            width: 100%;
            align-items: flex-start;
        }
        .a1sdd2 {
            width: 100%;
            z-index: -1;
            background-color: white;
        }
        .a4sdd{
            grid-template-columns: 1fr 1fr;
        }
        .a2sdddd{
            background-color: #EFFFCE;
            max-width: 100%;
            margin-bottom: 40px;
            border-radius: 20px;
            width: 100%;
        }
        .a2sdddd p{
            width: 70%;
        }
        .svg{
            margin-left: -35%;
        }
        .a1sdd2d h1{
            font-size: 25px;
        }
        .a1sdd2d h3{
            font-size: 15px;
        }
        .a1sdd2d p{
            font-size: 13px;
        }
        .a1sdd2dbtn {
            width: 100px;
            height: 35px;
        }
        .a1sdd1d{
            height: 75%;
            width: 75px;
            margin-left: -75px;
        }
        .quote-div h1 {
            font-size: 30px;
            width: 100%;
            margin-top: 50px;
        }
    }
    @media screen and (max-width: 600px) {
        .front, .back, .card{
            width: 250px;
            height: 310px;
        }
        .row{
            flex-direction: column;
        }
        .card-container{
            padding: 0px;
        }
        .gd{
            width: 90%;
        }
        .a2sd h1{
            font-size: 28px
        }
        .elementimg{
            margin-left: -34%;
            margin-top: -7%;
        }
        .a2sddd{
            background-color: transparent;
        }
        .elementdet3{
            margin-left: 63%;
        }
    }
    @media screen and (max-width: 535px) {
        .dot{
            width: 10px;
            height: 10px;
        }
        .quote-div{
            margin-top: 0px;
        }
        .a3sdd1 h1{
            font-size: 24px;
        }
        .a3sdd1 p{
            font-size: 12px;
        }
        .a3sdd1{
            width: 40%;
            margin-right: 20px;
            margin-left: 2%;
        }
        .a3sdd2d{
            width: 250px;
            height: 350px;
        }
        .a1s {
            margin-top: 100px;
            margin-bottom: 100px;
        }
        .ags1{
            margin-top: 50px;
        }
    }
    @media screen and (max-width: 510px) {
        .agdstepperbox h1{
            font-size: 27px;
        }
    }
    @media screen and (max-width: 460px) {
        
        
        .a2sd{
            padding-top: 20px;
            margin-bottom: 20px;
        }
        .a2sd h1{
            font-size: 30px;
            width: 100%;
        }
        .col-4{
            padding: 0px 5px ;
        }
        .dot{
            margin: 2px;
        }
        .a4sdd{
            grid-template-columns: 1fr;
        }
        .agdstepperbox h1{
            font-size: 20px;
        }
        .gd{
            width: 100%;
        }
        .elementjob{
            font-size: 16px;
        }
        .elementname{
            font-size: 16px;
        }
        .elementimg{
            margin-top: -10%;
            margin-left: -40%;
        }
        .elementdet3{
            margin-left: 74%;
            margin-top: 7%;
        }
        .quote-div h1{
            margin-top: 10px;
            font-size: 24px;
        }
    }
    @media screen and (max-width: 360px) {
        .vec-ab3s{
            display: none;
        }
        .a3sdd1 h1{
            font-size: 18px;
        }
        .a3sdd1 p{
            font-size: 12px;
        }
    }
    @media screen and (max-width: 340px) {
        .ags1{
            margin: 20px 5px;
        }
        .gd{
            height: fit-content;
            margin-bottom: 30px;
            margin-top: 20px;
        }
    }