.slug-ssd2{
    display: flex;
    flex-direction: column;
    margin: 20px 40px;
    width: 65%;
}
.slug-ss{
    display: flex;
    justify-content:center ;
    margin: 20px;
}
.slug-sdi{
    width: 100%;
    height: 450px;
}
.mobile-arrow{
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 50px;
    border: 1px solid black;
    align-items: center;
    text-align: center;
    justify-content: center;
    position: fixed;
    right: -10px;
    top: 94vh;
    box-shadow: 0 1px 4px rgb(146 161 176 / 15%);
    cursor: pointer;
    display: none;
    transition: 0.5s ease-in-out;
    z-index: 500;
}
.mobileBlogwidget-div{
    position: fixed;
    background-color: #e2e2e2;
    z-index: 499;
    top: 50px;
    display: flex;
    justify-content: center;
}
.innerdivmobwid{
    width: 90%;
    padding-bottom: 60px;
    height: 100vh;
    transition: height 500ms;
    overflow-x: hidden;
    padding-top: 30px;
}
.mobile-arrow:hover{
    background-color: #A2D83E;
    width: 60px;
    height: 60px;
    top: 92vh;
}
.lineslug{
    width: 100%;
    height: 1px;
    border-bottom: 1px solid grey;
    margin-top: 20px;
}
.Blogpost-title{
    font-size: 30px;
    font-weight: bold;
}
.Blogpost-content p{
    color: black;
    margin-top: 10px;
    font-size: 19px;
    margin-bottom: 10px;
    max-width: 100%;
}
.Blogpost-content{
    margin: 20px;
}
@media screen and (max-width: 1300px) {
    .slug-ssd2{
        width: 55%;
    }
  }
@media screen and (max-width: 800px) {
    .slug-ssd2{
        width: 100%;
    }
    .mobile-arrow{
        display: flex;
    }
}
@media screen and (max-width: 600px) {
    .slug-ssd2{
        margin: auto;
    }
}

