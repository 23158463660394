.NLWidget-cont{
    width: 100%;
    background-color: #010F2E;
    color: white;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 30px;
    border-radius: 10px;
}
.NLinput{
    width: 250px;
    border-radius: 50px;
    height: 50px;
    border: none;
    padding-left: 10px;
}
.NLbtn{
    background-color: #A2D83E;
    color: #fff;
    border-radius: 50px;
    height: 45px;
    width: 45px;
    position: absolute;
    border: none;
    margin-left: 200px;
}
.icon-box{
    margin: 15px;
}
.NLtitle{
    margin: 15px;
}
@media screen and (max-width: 1300px) {
    .NLWidget-cont{
        width: 100%;
    }
  }