
.inputGp{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}
.cpsdd{
    width: 60vh;
    margin-top: 30px;
}
.title-box-cp{
    display: flex;
    width: 50px;
    justify-content: space-between;
}
.cpsd h1{
    width: 100%;
    text-align: center;
}
.cpsd{
    align-items: center;
}
.tagsgp{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
.cps{
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: center;
}
.cpbtn{
    margin-top: 20px;
    width: 100px;
    border-radius: 5px;
}
.cpbtnd{
    align-items: center;
    text-align: center;
    justify-content: center;
}
.inputGp input[type=file]{
    padding: 10px;
    color: white;
}
.inputGp input{
    padding: 10px;
}
.inputGp textarea{
    padding: 10px;
}
.inputGp label{
    color: white;
}
.cpsd h1{
    margin-top: 50px;
    color: white;
    margin-bottom: 10px;
  }
.cp{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(https://images.unsplash.com/photo-1518531933037-91b2f5f229cc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=654&q=80);
}
@media screen and (max-width: 610px) {
    .inputGp{
        width: 80%;
    }
    .cps{
        height: 100vh;
    }
    .cpsd{
        width: inherit;
    }
    .cpsdd{
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        width: fit-content;
        width: auto;
    }
}
@media screen and (max-width: 500px) {
    .cps{
        height: -webkit-fill-available;
        padding-top: 30px;
        padding-bottom: 50px;
    }
}