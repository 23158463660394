@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
html{
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: 'DM Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.nfbi {
  background-image: url("https://images.unsplash.com/photo-1477346611705-65d1883cee1e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
code {
  font-family: 'DM Sans', sans-serif;
}
.nfp-sec{
  height: 65vh;
  display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.nfp-div{
  align-items: center;
  display: flex;
  flex-direction: column;
}
.nfp-div h1{
  font-size: 90px;
  width: 100%;
  color: white;
}
.nfp-div h3{
  width: 100%;
  color: white;
}
.btn-404{
  padding: 10px 20px;
  border: 1px solid #A2D83E;
  background-color: transparent;
  transition: 0.5s ease-in-out;
  border-radius: 10px;
  margin: 20px 5px;
  color: white;
}
.btn-404 a{
  color: white;
}
.btn-404:hover{
  background-color: #A2D83E;
}
