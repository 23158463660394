.intro-section h1{
    font-weight: bold;
    width: max-content;
    color: white;
}
.btn-filledhi{
    border: none;
    background-color: #A2D83E;
    color: #fff;
    width: 150px;
    border-radius: 20px;
    padding: 5px;
    margin-top: 10px;
    font-size: 15px;
    margin-right: 20px;
    transition: 0.5s ease-in-out;
}
.btn-outlinehi{
    border: 1px solid #A2D83E;
    background-color: transparent;
    color: #fff;
    width: 150px;
    border-radius: 20px;
    padding: 5px;
    margin-top: 10px;
    font-size: 15px;
    margin-right: 20px;
    transition: 0.5s ease-in-out;
}
.btn-outlinehi:hover{
    background-color: #A2D83E;
}
.btn-filledhi:hover{
    background-color: #54721b;
}
.intro-section h5{
    color: white;
    margin-top: 10px;
}
.intro-section{
    width: 500px;   
    margin-left: 10%;
}
.ab{
    color: white;
}
@media screen and (max-width: 645px) {
    .intro-section h1{
        font-size: 26px;
    }
    .intro-section h5{
        font-size: 18px;
    }
}
@media screen and (max-width: 400px) {
    .intro-section h1{
        font-size: 18px;
    }
    .intro-section h5{
        font-size: 14px;
    }
    .btn-outlinehi{
        width: 100px;
        font-size: 10px;
    }
    .btn-filledhi{
        width: 100px;
        font-size: 10px;
    }
}