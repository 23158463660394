.footer-newsletter-div{
    display: flex;
    color: #fff;
    margin-bottom: 50px;
    padding-top: 70px;
    justify-content: space-around;
    border-bottom: 1px dashed #5D5D5D;
    padding-bottom: 50px;
    align-items: center;
}
.footer-links-div{
    display: flex;
    color: #fff;
    padding-bottom: 40px;
    margin-left: 100px;
    margin-right: 100px;
    justify-content: space-between;
}
footer{
    background-color: #010F2E;
    padding-left: 100px;
    padding-right: 100px;
}
.btn-filled{
    background-color: #A2D83E;
    color: #fff;
    width: 160px;
    border-radius: 10px;
    height: 50px;
}
#email{
    border-radius: 10px;
    width: 350px;
    height: 50px;
    padding-left: 10px;
}
h4{
    border-left: 6px solid #A2D83E;
    padding-left: 10px;
}
p, a{
    color: #fff;
    text-decoration: none;
    font-size: 12px;
}
.links-div{
    display: flex;
    margin: 0;
    padding: 0;
}
.links-div a, .links-div p {
    margin-left: 9px;
    margin-bottom: 15px;
}
.footer-link-1{
    display: flex;
    width: 45%;
    justify-content: space-between;
}
h1{
    width: 30%;
}
form{
    align-items: center;
    justify-content: center;
    display: flex;
}
.footer-rights{
    display: flex;
    justify-content: space-between;
    background-color: #01233F;
    padding: 20px 50px 20px 50px;
}
.link-div{
    margin-top: 20px;
}
.blog-news{
    margin-top: 15px;
}
.blog-new p {
    margin-bottom: 3px;
    color: #5D5D5D;
}
.succesh1{
    width: 100%;
    color: #accc6b;
    font-weight: bold;
    align-items: center;
    text-align: center;
    justify-content: center;
    letter-spacing: 2px;
}
.footer-newsletter-div h1 {
    width: auto;
}
.blog-new{
    margin-bottom: 10px;
}
.to-blog{
    color: #fff;
    font-size: 13px;
    margin-top: 20px;
}
.to-blog:hover{
    color: #A2D83E;
}
.blog-new a{
    font-size: 18px;
    cursor: pointer;
}
.box-link-div{
    margin-bottom: 15px;
}
@media screen and (max-width: 1300px) {
    .footer-links-div{
        margin-left: 0px;
        margin-right: 0px;
    }
}
@media screen and (max-width: 1200px) {
    .footer-link-1{
        flex-direction: column;
    }
    .footer-links-div{
        margin-left: 50px;
        margin-right: 50px;
    }
}
@media screen and (max-width: 960px) {
    footer{
        padding-left: 50px;
        padding-right: 50px;
    }
}
@media screen and (max-width: 890px) {
    #email{
        width: 300px;
    }
}
@media screen and (max-width: 810px) {
    #email{
        width: 250px;
    }
    .btn-filled{
        width: 120px;
    }
    .footer-newsletter-div h1{
        font-size: 30px;
    }
    .footer-links-div{
        margin-left: 0px;
        margin-right: 0px;
    }
    .footer-rights p, a{
        font-size: 10px;
    }
}
@media screen and (max-width: 717px) {
    .footer-newsletter-div{
        flex-direction: column;
    }
    #email{
        width: 350px;
    }
    .btn-filled{
        width: 160px;
    }
    .footer-newsletter-div h1{
        margin-bottom: 20px;
        width: max-content;
    }
    .footer-link-1 {
        flex-direction: row;
        justify-content: space-between;
        width: auto;
    }
    .footer-links-div{
        flex-direction: column;
        margin-left: 30px;
        margin-right: 30px;
    }
}
@media screen and (max-width: 630px) {
    #email{
        width: 290px;
    }
    .btn-filled{
        width: 130px;
    }
    .footer-links-div{
        margin-left: 0px;
        margin-right: 0px;
    }
}
@media screen and (max-width: 530px) {
    #email{
        width: 200px;
    }
    .btn-filled{
        width: 120px;
    }
    .footer-newsletter-div h1{
        text-align: center;
        width: 100%;
    }
    .footer-links-div{
        margin-right: 0px;
    }
    .footer-link-1 {
        flex-direction: column;
    }
}
@media screen and (max-width: 480px) {
    .footer-newsletter-div{
        padding-top: 20px;
    }
    .footer-rights{
        padding: 20px 10px;
        text-align: center;
    }
}
@media screen and (max-width: 350px) {
    .footnewsform{
        flex-direction: column;
    }
    .footnewsform button{
        width: 100%;
    }
}