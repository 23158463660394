.Widget-cont{
    display: inline-block;
}
.Widget-box{
    border: 1px solid #F5F5F5;
    border-radius: 10px;
}
.title{
    padding: 10px;
    background-color: #F4F5F4;
    border-radius: 10px 10px 0px 0px;
}
.bar{
    padding: 20px;
    background-color: white;
    border-radius: 0px 0px 10px 10px;
}
.widget-title{
    font-weight: bold;
    font-size: 20px;
}
.tag-box{
    border: 1px solid #F4F5F4;
    width: auto;
    border-radius: 10px;
    margin-bottom: 10px;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    color: #5D5D5D;
    padding: 0px 10px;
    margin: 5px;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 13px;
    display: flex;
    height: 30px;
}
.tag-box:hover{
    background-color: #A2D83E;
    color: white;
}
.bar-tags form{
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
}
.bar-tags{
    background-color: white;
    border-radius: 0px 0px 10px 10px;
}