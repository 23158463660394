.nav-ul{
    list-style: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    margin-bottom: 0px;
    padding-left: 0;

}

.colorChange-navul{
    list-style: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    margin-bottom: 0px;
    padding-left: 0;
}
#togglePD{
    display: none;
}
.full-nav-link{
    display: flex;
    justify-content: space-around;
    width: 60%;
    align-items: center;
}
.hamburger-div{
    display: none;
}
.nav-div-ul{
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 100%;
}
.nav-div{
    display: flex;
    justify-content: space-between;
    padding: 20px 40px 20px 40px;
    background-color: transparent;
    color: white;
    transition: 0.5s ease-in-out;
}
.nava{
    cursor: pointer;
    color: white;
    text-decoration: none;
    font-size: inherit;
    transition: 0.2s ease-in-out;
}
.colorChange{
    background-color: white;
    display: flex;
    justify-content: space-between;
    padding: 20px 40px 20px 40px;
    transition: 0.5s ease-in-out;
}
.nav-ul a{
    cursor: pointer;
    color: #5D5D5D;
    text-decoration: none;
    font-size: inherit;
    transition: 0.2s ease-in-out;
}
.nav-ul a:hover {
    border-bottom: 2px solid #A2D83E;
    color: #A2D83E;
}
.nava:hover {
    color: #A2D83E;
}
.siwgbtn{
    border: 1px solid #A2D83E;
    border-radius: 10px;
    padding: 3px 10px;
    background: white;
    color: black;
    transition: 0.5s ease-in-out;
}
.siwgbtnw{
    border: 1px solid #A2D83E;
    border-radius: 10px;
    padding: 3px 10px;
    background: transparent;
    transition: 1s ease-in-out;
    color: #A2D83E;
}
.siwgbtn:hover{
    background-color: #A2D83E;
    color: white;
}
.siwgbtnw:hover{
    background-color: #A2D83E;
    color: white;
}

header{
    box-shadow: 0 1px 4px rgb(146 161 176 / 15%);
}
.btn-outline{
    border: 1px solid #A2D83E;
    color: #A2D83E;
    border-radius: 20px;
    background-color: transparent;
    text-decoration: none;
    transition: 0.5s ease-in-out;
    font-size: initial;
    padding: 3px 10px;
}
.menu-items{
    display: none;
}
.btn-outline:hover{
    background-color: #A2D83E;
    color: #fff;
}
.active{
    color:#A2D83E;
    border-bottom: 2px solid #A2D83E;
    cursor: pointer;
    text-decoration: none;
    font-size: inherit;
    width: fit-content;
  }
  .cpa{
    color: white;
    text-decoration: none;
    border: none;
    font-size: 15px;
}
.cpa:hover{
    border: none;
    color: white;
}   
  
  @media screen and (max-width: 1100px) {
    .full-nav-link{
        width: 70%;
    }
  }
  @media screen and (max-width: 930px) {
    .full-nav-link{
        width: 80%;
    }
  }
  @media screen and (max-width: 800px) {
    .full-nav-link{
        width: 90%;
    }
  }
  
  