.bg-p-dd{
    display: flex;
    font-size: 13px;
}
.bg-p-dd p{
    color: black;
}
.bg-p-intro p{
    color: black;
}
.tag-box{
    border: 1px solid #A2D83E;
    width: fit-content;
    border-radius: 5px;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    margin-right: 5px;
}
.tag-title{
    color: #A2D83E;
    padding: 3px;
    margin: 0;
    align-items: center;
    text-align: center;
    font-size: 12px;
}
.tag-box:hover{
    background-color: #A2D83E;
    color: white;
}
.bg-p-title h4{
    font-weight: bold;
    border: none;
    padding: 0;
    color: black;
    text-decoration: none;
}
.bg-p-tags{
    display: flex;
    border-left: 6px solid #A2D83E;
    padding-left: 10px;
    margin-bottom: -5px;
    margin-top: 10px;
}
.bg-p-data{
    display: flex;
    width: 100%;
    height: 15px;
    margin-top: 20px;
}
.vertical-lines{
    height: inherit;
    width: 1px;
    border-left: 1px solid grey;
    margin-right: 10px;
    margin-left: 10px;
}
.bg-p{
    width: 360px;
    border: 2px solid #F5F5F5;
    border-radius: 10px;
    margin: 5px 0px;
    height: fit-content;
    transition: 0.5s ease-in-out;
    background-color: white;
}
.icon{
    margin-right: 5px;
}

.btnu {
    border: none;
    font-size: 12px;
    border: none;
    color: white;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    padding: 0;
    transition: color 0.1s cubic-bezier(0.16, 0.08, 0.355, 1);
    display: inline-block;
    cursor: pointer;
    width: 100%;
    min-width: 200px;
    min-width: 20rem;
    outline: none;
    vertical-align: middle;
    text-align: center;
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
}

 .btn-border {
    background-color: transparent;
    color: #A2D83E;
}
 .btn-arrow {
    margin-top: 40px;
    position: relative;
    transition: background-color 300ms ease-out;
}
 .btn-arrow span {
    display: inline-block;
    position: relative;
    transition: all 300ms ease-out;
    will-change: transform;
    z-index: 0;
}
 .btn-arrow:hover span {
    transform: translate3d(-1rem, 0, 0);
}
 .btn-arrow svg {
    position: absolute;
    width: 1.1em;
    right: 0px;
    right: 0rem;
    opacity: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: all 300ms ease-out;
    will-change: right, opacity;
}
 .btn-arrow svg * {
    stroke-width: 5;
    stroke-color: transparent;
}
 .btn-arrow:hover svg {
    opacity: 1;
    right: -2rem;
}
.bg-p-ci img{
    width: 100%;
    border-radius: 10px 10px 0px 0px;
}
.bg-p-de, .bg-p-btn-box, .bg-p-tags-box{
    padding: 5px 20px;
}
#blogpost{
    margin-bottom: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.toblogbtn{
    margin-top: 30px;
    padding: 20px;
    border-radius: 5px;
}
.bg-p-data-widget{
    display: grid;
    width: 100%;
    height: 15px;
    grid-template-columns: 1fr 1fr;
}
.GEBPI{
    height: 65vh;
    width: 100%;
    size: cover;
    image-rendering: auto;
    -webkit-clip-path: polygon(0 0,100% 0,100% 82%,0 100%);
    clip-path: polygon(0 0,100% 0,100% 82%,0 100%);
}
section.bg-p:hover {
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
    background-color: #f5f5f5cf;
}
@media screen and (max-width: 1500px) {
    .bg-p{
        width: 310px;
    }
}
@media screen and (max-width: 1350px) {
    .bg-p{
        width: 400px;
    }
}
@media screen and (max-width: 1140px) {
    .bg-p{
        width: 310px;
    }
}
@media screen and (max-width: 1010px) {
    .GEBPI{
        height: 50vh;
        width: -webkit-fill-available;
    }
  }
  @media screen and (max-width: 900px) { 
    .bg-p{
        width: 400px;
    }
}
@media screen and (max-width: 800px) { 
    .GEBPI{
        height: 40vh;
    }
}
@media screen and (max-width: 790px) {
    .bg-p{
        width: 310px;
    }
}
@media screen and (max-width: 670px) {
    .bg-p{
        width: 360px;
    }
}
@media screen and (max-width: 400px) {
    .bg-p{
        width: 310px;
    }
    .bg-p-dd p{
        font-size: 10px;
    }
}