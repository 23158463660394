.blog-div{
    display: flex;
    padding: 20px;
    margin-bottom: 50px;
    justify-content: space-around;
    width: 100%;
}
.bg-p-blog{
    width: 300px;
}
.pbc-img{
    width: 70px;
    height: 70px;
}
.NewestBlogFullDiv{
    margin: 20px;
}
.Blogwidget-div{
    margin: 20px;
    width: min-content;
}
.pre-blog-cover{
    display: flex;
    align-items: center;
}
.pre-blog-detalis{
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.cat-section{
    width: 100%;
    background-color: #f4f5f4;
    height: 130px;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
}
.cat-div p{
    color: #A2D83E;
    letter-spacing: 7px;
    margin-bottom: 10px;
}
.cat-div h5{
    color: black;
    font-weight: bold;
}
.cat-div{
    padding: 20px;
}
.outbid{
    height: 50vh;
    background-image: url(https://images.unsplash.com/photo-1473773508845-188df298d2d1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1674&q=80);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
}
.bid{
    color: white;
}
.nfph1{
    width: 100%;
}
.bid h1{
    width: 100%;
    font-size: 45px;
}
@media screen and (max-width: 1400px) {
    .Blogwidget-div{
        width: 330px;
    }
}
@media screen and (max-width: 1350px) {
    .Blogwidget-div {
        max-width: 400px;
    }
}
@media screen and (max-width: 1010px) {
    .Blogwidget-div {
        max-width: 300px;
    }
}
@media screen and (max-width: 900px) {
    .Blogwidget-div {
        max-width: 400px;
    }
}
@media screen and (max-width: 800px) {
    .Blogwidget-div {
        width: 300px;
    }
    .bid h1{
        font-size: 30px;
    }
}
@media screen and (max-width: 800px) {
    .Blogwidget-div {
        display: none;
    }
    
}