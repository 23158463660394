.pdsd{
    width: 300px;
    height: fit-content;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    position: absolute;
    padding: 0px 10px;
}
.pdsddd img{
    height: 60px;
}
.userphoto{
    border-radius: 50px;
}
.pdsddd{
    display: flex;
    padding: 20px 20px 10px 20px;
    color: black;
    width: 100%;
    align-items: center;
}
.pdsddd h6{
    width: 100%;
}
.pdsdddd{
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    color: black;
    width: 100%;
}
.pdsdddd p{
    color: black;
}
.logout-btn{
    margin-bottom: 20px;
    background-color: transparent;
    border: 1px solid red;
    border-radius: 5px;
    transition: 0.5s ease-in-out;
    padding: 2px 5px;
}
.logout-btn:hover{
    background-color: red;
    color: white;
}
.triangle{
    position: absolute;
    margin-top: -12px;
    right: 6vh;
    color: white;
}
.createpost-btn{
    margin-bottom: 20px;
    background-color: blue;
    border: 1px solid blue;
    border-radius: 5px;
    transition: 0.5s ease-in-out;
    padding: 2px 5px;
}
.createpost-btn a{
    font-size: 15px;
    text-decoration: none;
}
.createpost-btn a:hover{
    color: white;
}
.createpost-btn:hover{
    background-color: rgb(7, 7, 149);
    color: white;
}
.pdsdd2{
    display: flex;
    justify-content: space-evenly;
}
.pds{
    position: absolute;
    z-index: 20;
    right: 40vh;
    margin-top: 20px;
}
.close-wrap{
    padding-top: 10px;
    padding-right: 10px;
    margin: 0;
    display: none;
    justify-content: end;
}
.close-btn{
    justify-content: center;
    display: flex;
    align-items: center;
    cursor: pointer;
}
@media only screen and (max-width: 900px) {
    .pds{
        right: 45vh;
    }
}
@media only screen and (max-width: 500px) {
    .pds{
        right: 50vh;
    }
    .pdsddd{
        padding-top: 0;
    }
    .close-wrap{
        display: flex;
    }
}
@media only screen and (max-width: 400px) {
    .pds{
        right: 47vh;
    }
}
@media only screen and (max-width: 380px) {
    .pds{
        right: 50vh;
    }
}