.services-intro{
    background-image: url(https://images.unsplash.com/photo-1522273987129-4ca3c41871e2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1954&q=80);
    height: 50vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: transparent;
    text-align: center;
    font-size: 50px;
    -webkit-text-stroke: 1px white;
}