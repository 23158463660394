.comment-container{
    display: flex;
    margin-bottom: 20px;
}
.comment-pic{
    width: 90px;
    height: 90px;
    border-radius: 50%;
}
.comment-box{
    width: 400px;
    margin-left: 30px;
}
.comment-de-box{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.comment-name h6{
    font-weight: bold;
    margin-bottom: 5px;
}
.comment-date p{
    color: #A2D83E;
    margin: 0;
}
.comment p{
    color: #5D5D5D;
    margin-top: 7px;
    font-size: 15px;
}
.btn-reply{
    background-color: transparent;
    border: 1px solid black;
    border-radius: 20px;
    padding: 5px;
    font-size: 14px;
}
.comment-container-reply{
    display: flex;
    margin-left: 120px;
    margin-top: 10px;
}
.comment-box-reply{
    width: 280px;
    margin-left: 30px;
}
.ch1{
    font-weight: 600;
}