.mobilenav{
  display: none;
  width: -webkit-fill-available;
  position: fixed;
}
.mobnava{
  cursor: pointer;
  color: black;
  text-decoration: none;
  font-size: inherit;
  transition: 0.2s ease-in-out;
  margin-bottom: 10px;
}
.moblogo{
  position: absolute;
  right: 10px;
  top: 7px;
}
.moblogo a img{
  width: 30px;
  height: 35px;
}
@media screen and (max-width: 500px) {
  .mobilenav{
    display: contents;
  }
}