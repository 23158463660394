.Widget-cont{
    display: inline-block;
    margin-bottom: 20px;
    width: 100%;
}
.Widget-box{
    border: 1px solid #F5F5F5;
    border-radius: 10px;
}
.title{
    padding: 10px;
    background-color: #F4F5F4;
    border-radius: 10px 10px 0px 0px;
}
.bar-pop{
    padding: 20px;
    background-color: white;
    border-radius: 0px 0px 10px 10px;
}
.pre-blog-box{
    display: flex;
    margin-bottom: 10px;
}
.widget-title{
    font-weight: bold;
    font-size: 20px;
    border-left: 6px solid #A2D83E;
    padding-left: 10px;
}
a{
    color: #5D5D5D;
    font-size: 14px;
}
.line22{
    border-bottom: 1px solid #F5F5F5;
    margin-top: 10px;
    margin-bottom: 10px;
}
.date{
    margin-bottom: 4px;
    color: #5D5D5D;
    font-size: 14px;
}
.pop-title{
    text-decoration: none;
    font-weight: bold;
    color: black;
    font-size: 14px;
}
.pop-title:hover{
    color: #A2D83E;
}
