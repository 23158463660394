.Widget-cont{
    display: inline-block;
}
.Widget-box{
    border: 1px solid #F5F5F5;
    border-radius: 10px;
}
.search-input{
    width: -webkit-fill-available;
    border-radius: 10px 0px 0px 10px;
    border: none;
    background-color: #F6F6F6;
    height: 70px;
    padding-left: 10px;
}
.btn-input{
    height: 70px;
    border: none;
    background-color: #A2D83E;
    color: #fff;
    width: 100px;
    border-radius: 0px 10px 10px 0px;
}
.title{
    padding: 10px;
    background-color: #F4F5F4;
    border-radius: 10px 10px 0px 0px;
}
.bar{
    padding: 20px;
}
.widget-title{
    font-weight: bold;
    font-size: 20px;
}