.card-box, .card-box-rev{
    background-color: #F2F3F2;
    display: flex;
    max-width: 400px;
    margin: 15px;
    padding: 20px;
}
.card-box-rev{
    text-align: end;
    justify-content: end;
    max-width: 400px;
}
.card-svgbox, .card-svgbox-rev{
    background-color: white;
    padding: 10px;
    margin-right: 10px;
    height: fit-content;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.card-svgbox-rev{
    margin-right: 0;
    margin-left: 10px;
}
.card-det h5{
    font-weight: bold;
}
.card-det p{
    color: #5D5D5D;
    margin: 0;
}
.cards-box{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.cards{
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 40px;
    margin-bottom: 40px;
}



.contactme-box{
    width: 100%;
    display: flex;
    height: 468px;
}
.contactme-de-box{
    background-color: #01233F;
    width: 50%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.contactme-form-box{
    width: 50%;
    background-color: #F6F6F6;
    display: flex;
    align-items: center;
}
.contactme-form{
    display: initial;
    margin-left: 30px;
    padding: 20px;
}

.inp-text{
    width: 90%;
    height: 200px;
    border: none;
    resize: none;
    padding: 10px;
}
.inp-btn{
    background-color: transparent;
    border: 1px solid #A2D83E;
    color: #5D5D5D;
    width: 180px;
    border-radius: 10px;
    margin-top: 10px;
    transition: 0.5s ease-in-out;
    height: 40px;
}
.inp-btn:hover{
    color: #FFF;
    background-color: #A2D83E;
}
.contact-me-p{
    font-size: 18px;
    margin-top: 10px;
}
.d1{
    margin-top: 10px;
    border: 2.5px solid #A2D83E;
    width: 70px;
    background-color: #A2D83E;
    margin: 15px 2px 4px;
}
.d2{
    margin: 1px 2px;
    border: 1px solid #A2D83E;
    width: 100px;
    background-color: #A2D83E;

}
.map{
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}
.inp{
    height: 60px;
    width: 44%;
    border: none;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-right: 10px;
    padding: 0px 10px;
}
.inp1{
    background-image: url(https://cdn-icons-png.flaticon.com/512/1077/1077063.png);
    background-position: right;
    background-size: 20px;
    background-repeat: no-repeat;
    background-origin: content-box;
}
.inp2{
    background-image: url(https://cdn-icons-png.flaticon.com/512/646/646094.png);
    background-position: right;
    background-size: 20px;
    background-repeat: no-repeat;
    background-origin: content-box;
}
#input1{
    border-radius: none;
}
.cid{
    background-image: url(https://images.unsplash.com/photo-1534536281715-e28d76689b4d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80);
    height: 50vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: transparent;
    text-align: center;
    flex-direction: column;
}
input:focus, textarea:focus{
    outline: none;
}
@media screen and (max-width: 960px) {
    .contactme-form{
        width: inherit;
    }
    .contactme-box{
        flex-direction: column;
        height: auto;
    }
    .contactme-form-box{
        width: 100%;
    }
    .contactme-de-box{
        width: 100%;
        height: 360px;
    }
  }
@media screen and (max-width: 654px) {
    .cards-box{
        grid-template-columns: 1fr;
    }
  }
  .icon-contact{
    position: absolute;
    left: -180px;
    z-index: 0;
    opacity: 0.2;
  }
  .cmdeb2{
    z-index: 2;
  }
  @media screen and (max-width: 500px) {
    .contactme-form{
        margin: auto;
    }
    .inp{
        width: 100%;
    }
    .inp-text{
        width: 100%;
        margin-bottom: 10px;
        margin-top: 10px;
        margin-right: 10px;
    }
    .inp-btn{
        width: -webkit-fill-available;
    }
  }