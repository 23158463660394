.prevnext-box{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 30px;
}
.prenext-cont{
    width: auto;
}
.next-box a, .prev-box a{
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    color: #000;
}
.next-box p, .prev-box p{
    color: #5D5D5D;
    margin-bottom: 10px;
}
.next-box{
    text-align: end;
}
.line23{
    border-top: 2px solid #F5F5F5;
}
.designelement-box{
    text-align: center;
}
@media screen and (max-width: 1000px) {
    .next-box a, .prev-box a{
        font-size: 17px;
    }
  }
  @media screen and (max-width: 450px) {
    .next-box a, .prev-box a{
        font-size: 14px;
    }
    .pnpsvg{
        width: 30px;
    }
    .prevnext-box{
        grid-template-columns: 1fr 0.25fr 1fr;
    }
  }