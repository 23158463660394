.home1{
    bottom: 0;
    z-index: -1;
    position: absolute;
}
.home2{
    width: 900px;
    z-index: -2;
    position: absolute;
    right: 0px;
}
.landing-div{
    height: 100vh;
    background-image: url(https://images.unsplash.com/photo-1542601906990-b4d3fb778b09?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2026&q=80);
    margin-top: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.normalnav-section{
    position: fixed;
    width: -webkit-fill-available;
    z-index: 10;
}
.cbg{
    width: 100%;
    position: absolute;
    z-index: -1;
    height: min-content;
}
.scroll-text{
    position: absolute;
    right: 22%;
    bottom: 6%;
    font-size: 20px;
    color: white;
    letter-spacing: 2px;
    transition: 0.5s ease-in-out;
}
.covernav{
    display: flex;
    justify-content: space-between;
    width: 140px;
    margin-top: 10px;
}
.covernavmore{
    display: flex;
    justify-content: space-between;
    width: 270px;
}
.BPIHSVG1{
    position: absolute;
    left: 20vh;
    z-index: -10;
    margin-top: 20vh;
}
.BPIHSVG2{
    position: absolute;
    right: 20vh;
    z-index: -10;
    margin-top: 40vh;
}
.BPIHSVG3{
    position: absolute;
    left: 10vh;
    z-index: -10;
    margin-top: 120vh;
}
.covernavp:hover{
    color: white;
}
.covernavp{
    font-size: 18px;
}
.bold{
    font-weight: bold;
}
.scroll-text:hover{
    color: white;
    bottom: 5%;
}
.navtohome{
    position: absolute;
    color: white;
    z-index: 10;
    font-size: 23px;
    margin-top: 55vh;
    margin-left: 6vh;
    transition: 0.5s ease-in-out;
}
.navtohome:hover{
    color: #A2D83E;
}
.scroll-text2{
    position: absolute;
    right: 8%;
    bottom: -88%;
    font-size: 20px;
    color: black;
    letter-spacing: 10px;
    transition: 0.5s ease-in-out;
}
.scroll-text2:hover{
    color: black;
    bottom: -90%;
}
.scroll-text3{
    position: absolute;
    font-size: 20px;
    color: black;
    letter-spacing: 10px;
    transition: 0.5s ease-in-out;
    right: 8%;
    margin-top: 10%;
}
.scroll-text3:hover{
    color: black;
    margin-top: 11%;
}
.step-box{
    height: 65px;
    width: 65px;
    align-items: center;
    text-align: center;
    border: 1px solid #A2D83E;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    margin: 10px 20px;
    cursor: pointer;
    padding: 14px;
    transition: 0.5s ease-in-out;
}
.step-box:hover{
    color: white;
    background-color: #A2D83E;
    fill: white;
}
.step-title-box{
    display: flex;
    justify-content: space-between;
}
.stepper-box{
    display: flex;
    margin: 30px 60px;
}
.vertical-line{
    height: 80px;
    border: 1px dotted #A2D83E;
    width: 1px;
}
.step-title-box h2{
    margin-top: 20px;
    margin-left: 40px;
    width: 45%;
}
.bookwidget-box{
    width: 440px;
    height: auto;
    margin-right: 40px;
}
.bookwidget-title p{
    color: #5D5D5D;
}
.bookwidget-title h3{
    font-weight: bold;
    margin-top: 22px;
}
.BooksWidget-box{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    margin-left: 40px;
    overflow-y: scroll;
}
.BooksWidget-box::-webkit-scrollbar {
    display: none; 
}
.BooksWidget-box{
    -ms-overflow-style: none; 
    scrollbar-width: none; 
    scroll-behavior: smooth;
}
.watch-btn{
    border: none;
    background-color: white;
    color: #A2D83E;
    padding: 10px 30px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    position: absolute;
    transition: 0.5s ease-in-out;
    margin-top: -26px;
    margin-left: 248px;
}
.watch-btn-div{
    position: sticky;
}
.watch-btn:hover{
    background-color: #A2D83E;
    color: white;
}
.watch-btn:focus{
    outline: none;
}
.arrowright{
    margin-left: 5px;
}
.coverimg-book{
    width: 440px;
    height: 250px;
}
.svg1{
    position: absolute;
    z-index: -1;
    left: 10%;
    margin-top: 200px;
}
.svg2{
    position: absolute;
    z-index: -1;
    right: 15%;
    margin-top: 200px;

}
.reveal, .revealRight{
    transform: translateY(50px);
    opacity: 0;
    transition: 1s all ease;
  }
  
  .reveal.active3{
    transform: translateY(0);
    opacity: 1;
  }
  .revealRight.active4{
    transform: translateY(0);
    opacity: 1;
  }
.NewestBlogFullDiv{
    display: grid;
    column-gap: 5px;
    margin: 0;
    grid-template-columns: 1fr 1fr 1fr;
}    
#newestblog h2{
    margin-bottom: 20px;
    font-weight: bold;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.bwlink{
    color: #A2D83E;
}
#newestblog{
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
}
#greenenergybook{
    height: 75vh;
}

@media screen and (max-width: 1350px) {
    .NewestBlogFullDiv{
        grid-template-columns: 1fr 1fr;
    }
    .homenb {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 1080px) {
    .home2{
        width: 700px;
    }
    .home1{
        height: 300px;
    }
    .img1{
        width: 250px;
    }
    .img2{
        width: 250px;
        bottom: -75%;
    }
    .step-title-box h2{
        width: 60%;
    }
    #greenenergybook {
        height: min-content;
    }
    .homenb {
        grid-template-columns: 1fr 1fr;
    }
    .homebp {
        width: 500px;
    }
}
@media screen and (max-width: 1010px) {
    .navtohome{
        margin-top: 40vh;
    }
}
@media screen and (max-width: 900px) {
    .NewestBlogFullDiv{
        display: block;
    }
}
@media screen and (max-width: 880px) {
    .step-title-box h2{
        width: 70%;
    }
    .stepper-box{
        margin: 10px 20px;
        margin-top: 30px;
    }
    .step-box{
        width: 50px;
        height: 50px;
    }
    .vertical-line{
        height: 65px;
    }
    .landing-div{
        height: 50vh;
    }
}
@media screen and (max-width: 800px) {
    .navtohome{
        margin-top: 30vh;
    }
}
@media screen and (max-width: 755px) {
    .BPIHSVG3{
        display: none;
    }
    .BPIHSVG1{
        display: none;
    }
    .NewestBlogFullDiv{
        grid-template-columns: 1fr 1fr;
        display: grid;
    }
}
@media screen and (max-width: 700px) {
    .home2{
        width: 550px;
        margin-top: 80px;
    }
    .bookwidget-box{
        width: 330px;
    }
    .watch-btn{
        margin-left: 170px;
    }
    .step-title-box h2{
        width: 90%;
        font-size: 21px;
    }
    .step-box{
        margin: 0px 10px;
    }
    .vertical-line{
        height: 50px;
    }
    .coverimg-book {
        width: 350px;
        height: 200px;
    }
}
@media screen and (max-width: 670px) {
    .NewestBlogFullDiv{
        display: block;
    }
}
@media screen and (max-width: 570px) {
    .home2{
        width: 400px;
        margin-top: 80px;
    }
    .step-title-box h2{
        width: 90%;
        font-size: 18px;
    }
    .coverimg-book {
        width: 300px;
        height: 180px;
    }
    .bookwidget-box {
        margin-right: 20px;
        width: 300px;
    }
    .watch-btn {
        padding: 5px 15px;
        margin-top: -20px;
    }
    .bookwidget-title h3 {
        font-size: 20px;
    }
    .step-box {
        width: 40px;
        height: 40px;
        padding: 10px;
    }
    .vertical-line {
        height: 40px;
    }
}
@media screen and (max-width: 500px) {
    .normalnav-section{
        display: none;
    }
    .stepperwrap{
        display: none;
    }
    .step-title-box h2{
        margin-top: 30px;
        margin-bottom: 30px;
    }
  }
@media screen and (max-width: 400px) {
    .svg1{
        display: none;
    }
}
@media screen and (max-width: 350px) {
    .step-title-box h2{
        margin-left: 10px;
    }
    .BooksWidget-box{
        margin-left: 10px;
    }
}
