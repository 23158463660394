.pis{
    padding: 200px 0px;
    background-image: url(../assets/henry-co--odUkx8C2gg-unsplash\ 2.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.pisd-wrap{
    display: flex;
    padding: 0px 150px;
}
.pipd{
    width: 55%;
    justify-content: space-around;
    display: flex;
    padding: 20px 80px;
}
.pipdet p{
    width: 70%;
    margin-top: 12px;
}
.pipdet{
    width: 45%;
    padding: 20px;
}
.pipdetbtns{
    display: flex;
}
.btn-filled2{
    border: none;
    width: 150px;
    height: 40px;
    border-radius: 5px;
    background-color: #c2e483;
    color: black;
    margin-right: 20px;
    transition: 0.5s ease-in-out;
    font-size: 13px;
}
.btn-filled2:hover{
    color: white;
    background-color: #A2D83E;
}
.btn-outline2{
    border: 1px solid #A2D83E;
    width: 150px;
    height: 40px;
    border-radius: 5px;
    background-color: white;
    color: black;
    margin-right: 20px;
    transition: 0.5s ease-in-out;
    font-size: 13px;
}
.btn-outline2:hover{
    color: white;
    background-color: #A2D83E;
}
.pdi2d-wrap{
    display: flex;
    padding: 50px 200px;
    margin-top: 90px;
    margin-bottom: 40px;
}
.pdi2d{
    display: flex;
    justify-content: space-between;
    text-align: center;
    width: 100%;
}
.pdiditem{
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 30px;
    height: 330px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.itemdet p{
    color: black;
    font-size: 15px;
}
.faq-wrap{
    padding: 0px 200px;
    margin-bottom: 60px;
    margin-top: 20px;
}
.pfaqs{
    background-color: #F4F5F4;
    padding: 50px 0px 50px 0px;
    margin-top: 30px;
}
.pgs{
    margin-top: 100px;
    margin-bottom: 100px;
}
.pgssd{
    display: flex;
}
.greetitem{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    text-align: center;
}
.greet-wrap{
    display: flex;
    width: 100%;
    justify-content: center;
}
.greet-div{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100vh;
}
.greetitem-wrap{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    width: 40vh;
    height: fit-content;
    border-radius: 10px;
}
.active_greetings{
    width: 70px;
    height: 7px;
    border: 1px solid gray;
    border-radius: 10px;
    background-color: #A2D83E;
    cursor: pointer;
}
.unactive_greetings{
    width: 70px;
    height: 7px;
    border: 1px solid gray;
    border-radius: 10px;
    background-color: transparent;
    transition: 0.5s ease-in-out;
    cursor: pointer;
}
.unactive_greetings:hover{
    background-color: #A2D83E;
}
.stepper_greet_wrap{
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 40px;
}
.stepper_greet{
    display: flex;
    width: 150px;
    justify-content: space-between;
}
.dtline{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    row-gap: 20px;
    margin-bottom: 10px;
}
.pdt-cont{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

}
.pdts{
    padding: 0px 300px;
    text-align: center;
    width: 100%;
}
.dtnhalf{
    background-color: #80C783;
    padding: 5px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.pdcill{
    position: absolute;
    width: 80px;
    margin-top: -33.5vh;
}
.graydiv{
    height: 50vh;
    background-color: #F4F5F4;
    width: 100%;
    position: absolute;
    z-index: -14;
    margin-top: -22vh;
}
.datadiv{
    width: 100%;
}
.dets{
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 0% 20%;
}
.datadiv h5{
    width: 100%;
    font-weight: bold;
}
@media screen and (max-width: 1670px) {
    .pdi2d-wrap{
        padding: 0px 100px;
    }
}
@media screen and (max-width: 1430px) {
    .pdi2d-wrap{
        padding: 0px 50px;
    }
}
@media screen and (max-width: 1350px) {
    .pdi2d-wrap{
        padding: 0px 20px;
    }
}
@media screen and (max-width: 1300px) {
    .pdts{
        padding: 0px 150px;
    }
    .pgs{
        padding: 0px 150px;
    }
    .dets{
        padding: 0px 150px;
    }
    .pdi2d{
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        justify-content: center;
    }
    .pdiditem{
        width: auto;
        margin: 25px;
    }
    .pisd-wrap{
        padding: 0px 50px;
    }
    .faq-wrap{
        padding: 0px 150px;
    }
}
@media screen and (max-width: 1050px) {
    .pipd img{
        width: 200px;
        height: 250px;
    }
}
@media screen and (max-width: 1000px) {
    .pdts{
        padding: 0px 100px;
    }
    .pgs{
        padding: 0px 50px;
    }
    .dets{
        padding: 0px 100px;
    }
    .pdi2d-wrap{
        padding: 0px 50px;
    }
    .faq-wrap{
        padding: 0px 100px;
    }

}
@media screen and (max-width: 890px) {
    .pisd-wrap{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .pipd{
        width: 100%;
    }
    .pipdet{
        width: 100%;
    }
}
@media screen and (max-width: 830px) {
    .greet-div{
        flex-direction: column;
    }
    .greetitem-wrap{
        margin: 10px;
        width: 100%;
    }
}
@media screen and (max-width: 800px) {
    .pdts{
        padding: 0px 30px;
    }
    .pgs{
        padding: 0px 30px;
    }
    .dets{
        padding: 0px 30px;
    }
    .pdi2d-wrap{
        padding: 0px 30px;
    }
    .faq-wrap{
        padding: 0px 30px;
    }
    .pisd-wrap{
        padding: 0px 30px;
    }
}
@media screen and (max-width: 650px) {
    .dets{
        flex-direction: column;
        margin-bottom: 20px;
    }
    .graydiv{
        margin-top: -33vh;
    }
}
@media screen and (max-width: 600px) {
    .pdi2d{
        grid-template-columns: 1fr;
    }
    .pipdet{
        padding: 0px;
    }
    .pdts{
        padding: 0px;
    }
    .pdiditem{
        height: 200px;
    }
    .pdcill{
        margin-top: -10.5vh;
    }
}
@media screen and (max-width: 460px) {
    .pdiditem{
        height: auto;
        margin-bottom: 40px;
    }
    .btn-filled2{
        width: 110px;
        font-size: 11px;
    }
    .btn-outline2{
        width: 110px;
        font-size: 11px;
    }
    .graydiv{
        margin-top: -40vh;
    }
}